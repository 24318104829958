<template>
  <div class="hospital-map">
    <div class="map-wrapper">
        <!-- 体检网点选择容器 -->
        <div class="map-select">
          <el-input placeholder="请输入您要搜索的体检网点" v-model="Location" class="input-with-select" @input="tapELInput" @focus="tapInputFocus">
            <el-select v-model="scity" slot="prepend" placeholder="请选择" @change="tapSelectBlur">
              <el-option v-for="item in orignList" :key="item.regionCode" :label="item.regionName" :value="item.regionCode"></el-option>
            </el-select>
            <el-button class="btns" slot="append" icon="el-icon-search" @click="goBaiduMap()"></el-button>
          </el-input>
          <div class="drop-downselect" v-show="dropShow && dataSheetList.length > 0">
            <div class="drop-item" v-for="item in dataSheetList" :key="item.hospitalId" @click="tapSheetList(item.hospitalName)">
              <div class="info">
                <p class="info-title">{{item.hospitalName}}</p>
                <!-- <p class="info-address">洛阳市钱江世纪城广孚联合国际中心裙楼2楼洛阳市钱江世纪城广孚联合国际中心裙楼2楼</p>
                <p class="info-address">电话：4000890999</p> -->
              </div>
              <!-- <div class="info-img">
              </div> -->
            </div>
          </div>
        </div>
        <!-- 地图容器 -->
        <div class="map-context">
          <baidu-map :style="{width:map.width,height:map.height}" class="map" ak="kwCrzOkUIYBl1YV7Q0MPGqWLn4oW2Cje" :zoom="map.zoom" :center="{lng: map.center.lng, lat: map.center.lat}" @ready="handler" :scroll-wheel-zoom="true">
              <!--比例尺控件-->
              <bm-scale anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-scale>
              <!--缩放控件-->
              <bm-navigation anchor="BMAP_ANCHOR_BOTTOM_RIGHT" ></bm-navigation>
              <!--城市选择列表-->
              <city-list></city-list>
          </baidu-map>
        </div>
    </div>
  </div>
</template>

<script>
// 百度地图
import BaiduMap from 'vue-baidu-map/components/map/Map.vue'
import BmScale from 'vue-baidu-map/components/controls/Scale'
import BmNavigation from 'vue-baidu-map/components/controls/Navigation'
import CityList from 'vue-baidu-map/components/controls/CityList'

import { queryIndexOrignList, queryNoLoadingGetHospitalList } from '@/api/herbalMedicine'

export default {
  name: 'HospitalMap',
  components: {
    BaiduMap,
    BmScale,
    BmNavigation,
    CityList
  },
  data () {
    return {
      map:{
        center: {lng:120.219375416, lat:30.2592444615},//'洛阳市',120.219375416,30.2592444615
        zoom: 12,
        width:'100vw',
        height:'700px'
      },
      // 全部区域列表
      orignList: [],
      // 驾校列表
      dataSheetList: [],
      Location:"",
      scity: 'all',
      // 分页信息
      pageObj: {
        pageIndex: 1,
        pageSize: 10,
        total: 0
      },
      // 下拉框是否展示
      dropShow: false,
      // el-select失去焦点
      selectBlur: false,
    }
  },
  computed: {
  },
  created () {
    if (this.$route.query && this.$route.query.location) {
      this.Location = this.$route.query.location
      this.getQueryGetHospitalList()
    }
    // 全部区域列表
    this.getQueryIndexOrignList()
  },
  methods: {
    // 全部区域列表
    getQueryIndexOrignList () {
      queryIndexOrignList().then(res => {
        res.data.unshift({regionCode: "all", regionName: "全城"})
        this.orignList = res.data
        if (this.$route.query && this.$route.query.city) {
          this.orignList.map(item => {
            if (item.regionName === this.$route.query.city) this.scity = item.regionCode
          })
        }
        this.selectBlur = true
      })
    },
    // 地图初始化
    handler ({BMap, map}) {
        console.log(BMap, map)
        map.disableScrollWheelZoom() //禁止缩放
        // this.map.width= document.getElementById("container").clientWidth-330+'px';
        // this.map.height=document.body.clientHeight -160+'px';
    },
    // 查询医院列表
    getQueryGetHospitalList () {
      this.dropShow = false
      this.dataSheetList = []
      queryNoLoadingGetHospitalList({ 
        // isPublish: 1,
        // pageIndex: this.pageObj.pageIndex,
        // pageSize: this.pageObj.pageSize,
        district: this.scity == 'all' ? '' : this.scity,
        hospitalName: this.Location == '' ? '' : this.Location
      }).then(res => {
        if (res.data.length > 0) {
          this.dataSheetList = res.data
          this.dropShow = true
        } else {
          this.dataSheetList = res.data
          this.showMessage('未找到结果', "error");
        }
      })
    },
    tapELInput (e) {
      this.getQueryGetHospitalList()
    },
    tapSelectBlur (e) {
      // el-select失去焦点
      this.selectBlur=true
      this.Location=''
      this.dropShow = false
      if (this.Location != '') {
        this.getQueryGetHospitalList()
      }
    },
    tapInputFocus (e) {
      if (this.selectBlur) {
        this.getQueryGetHospitalList()
      } else {
        this.dropShow = false
      }
    },
    tapSheetList(name) {
      this.Location = name
    },
    goBaiduMap(){
      if (this.Location == "") {
        const _scity = this.scity;
        let _name;
        this.orignList.forEach(item => {
          if (item.regionCode == _scity) {
            _name = item.regionName
          }
        })
        if (_name == '全城') _name = '洛阳市'
        window.open(
          `https://api.map.baidu.com/geocoder?address=${_name}体检网点&output=html&region=杭州&src=我的位置`,
          "_blank"
        );
      } else {
        const schoolName = this.Location
        // window.location.href = "http://api.map.baidu.com/geocoder?address="+this.Location +"&output=html&src=我的位置"
        window.open("https://api.map.baidu.com/geocoder?address="+schoolName +"&output=html&src=我的位置",'_blank')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>